import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import * as _ from 'lodash';
import FormValidator from '../libs/misc/form-validator';
import AVLive from '../libs/misc/mil-av-live';
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window, document) {
  // shim layer with setTimeout fallback
  window.requestAnimationFrame = function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };
  }();
  var getViewport = function getViewport() {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    return {
      x: x,
      y: y
    };
  };
  var password_options_en = {
    enterPass: 'Type your password.',
    shortPass: 'Password too short, 6 char at least.',
    containsField: 'The password contains your username',
    badPass: "Weak; try combining letters & numbers",
    goodPass: "Medium; try using special characters",
    strongPass: "Strong password",
    steps: {
      15: 'Really insecure password',
      35: 'Weak; try combining letters & numbers',
      70: 'Medium; try using special characters',
      90: 'Strong password'
    },
    showPercent: false,
    showText: true,
    animate: true,
    minimumLength: 6
  };
  var password_options_it = {
    enterPass: 'Scegli la tua password',
    shortPass: 'La password è troppo corta, minimo 6 caratteri',
    containsField: 'La password contiene il tuo username',
    badPass: "Password facile, prova a combinare lettere e numeri",
    goodPass: "Password media, prova ad usare anche caratteri speciali",
    strongPass: "Password sicura",
    steps: {
      15: 'Password non sicura',
      35: 'Password facile, prova a combinare lettere e numeri',
      70: 'Password media, prova ad usare anche caratteri speciali',
      90: 'Password sicura'
    },
    showPercent: false,
    showText: true,
    animate: true,
    minimumLength: 6
  };
  var password_options_mix = {
    enterPass: 'Scegli la tua password / Type your password',
    shortPass: 'La password è troppo corta, minimo 6 caratteri / Password too short, 6 char at least',
    containsField: 'La password contiene il tuo username / The password contains your username',
    badPass: "Password facile, prova a combinare lettere e numeri / Weak; try combining letters & numbers",
    goodPass: "Password media, prova ad usare anche caratteri speciali / Medium; try using special characters",
    strongPass: "Password sicura / Strong password",
    steps: {
      15: 'Password non sicura / Really insecure password',
      35: 'Password facile, prova a combinare lettere e numeri / Weak; try combining letters & numbers',
      70: 'Password media, prova ad usare anche caratteri speciali / Medium; try using special characters',
      90: 'Password sicura / Strong password'
    },
    showPercent: false,
    showText: true,
    animate: true,
    minimumLength: 6
  };
  function init_common() {
    $('body').removeClass('preloading');
    if ($('#nav-toggler').length == 0) return;
    $('#nav-toggler').click(function () {
      $(this).toggleClass('open');
      $('#nav-ul-wrapper').toggleClass('open');
    });
    window.onresize = _.debounce(closeNav, 20);
  }
  var closeNav = function closeNav() {
    $('#nav-ul-wrapper').removeClass('open');
    $('#nav-toggler').removeClass('open');
  };
  function init_actionbox() {
    var $actionBox = $('#action-box');
    $actionBox.on('show.bs.modal', function (e) {
      $actionBox.find('input[name="name"]').val('');
      $actionBox.find('input[name="address"]').val('');
      $actionBox.find('input[name="postcode"]').val('');
      $actionBox.find('input[name="city"]').val('');
      $actionBox.find('.action-errors').empty();
    });
    $actionBox.on('click', '#btn-new-pharma', function (e) {
      e.preventDefault();
      var $btn = $(e.relatedTarget);
      var $errors = $actionBox.find('.action-errors');
      var err = false;
      var indata = {};
      $btn.prop('disabled', 'disabled');
      $errors.empty().removeClass('errors');
      indata.name = $actionBox.find('input[name="name"]').val();
      indata.address = $actionBox.find('input[name="address"]').val();
      indata.postcode = $actionBox.find('input[name="postcode"]').val();
      indata.city = $actionBox.find('input[name="city"]').val();
      if (!indata.name) {
        err = true;
        $errors.append("\n                    <span class=\"error\">Inserisci un nome di farmacia valido</span>\n                ");
      }
      if (!indata.address) {
        err = true;
        $errors.append("\n                    <span class=\"error\">Inserisci un indirizzo valido</span>\n                ");
      }
      if (!indata.postcode) {
        err = true;
        $errors.append("\n                    <span class=\"error\">Inserisci un CAP valido</span>\n                ");
      }
      if (!indata.city) {
        err = true;
        $errors.append("\n                    <span class=\"error\">Inserisci una citt\xE0 valida</span>\n                ");
      }
      if (err) {
        $errors.addClass('errors');
        return false;
      }
      $errors.append('<i class="fa fa-2x fa-spin fa-spinner"></i>');
      var jqxhr = $.ajax({
        method: "POST",
        url: '/add-pharmacy',
        data: indata,
        dataType: 'json'
      }).done(function (reply) {
        console.log('reply', reply);
        $('#selph').select2("trigger", "select", {
          data: reply
        });

        // $( '#selph' ).val(reply.id)

        // $( '#selph' ).trigger('change')
        $actionBox.modal('hide');
      }).fail(function (e) {
        console.log("error", jqxhr, e);
        $errors.empty().addClass('errors').append(jqxhr.responseJSON.message);
      }).always(function () {
        $btn.removeAttr('disabled');
      });
    });
    return $actionBox;
  }

  //     function init_register() {

  //         const $actionBox = init_actionbox()

  //         const registerFormValidator = new FormValidator({
  //             form: {
  //                 id: "#register-form",
  //                 errors: ".register-errors",
  //                 btn_submit: "#btn-register",
  //                 recaptcha: "#g-recaptcha-response",
  //             },
  //             fields: [
  //                 "name",
  //                 "surname",
  //                 "role",
  //                 "pharmacy",
  // //                "company",
  //                 "city",
  //                 "email",
  //                 "phone",
  //                 "password",
  //                 "password_check",
  //                 "remember",
  //                 "remember2",
  //             ],
  // //            init_fields: false,
  //             init_fields: [
  //                 {
  //                     name: 'role',
  //                     type: 'select2',
  //                     params: {
  //                         placeholder: 'Ruolo',
  //                         allowClear: false,
  //                     },
  //                 },
  //                 {
  //                     name: 'pharmacy',
  //                     type: 'select2',
  //                     selecting: function (e) {
  //                         console.log('e', e)
  //                         console.log('e.params', e.params)
  //                         console.log('e.params.args', e.params.args)
  //                         console.log('e.params.args.data', e.params.args.data)
  //                         if (e.params.args.data && e.params.args.data.id == -1) {

  //                             e.preventDefault()

  //                             $(this).select2('close');

  //                             $actionBox.modal('show')

  //                             return false

  //                         }
  //                     },
  //                     params: {
  //                         placeholder: 'Nome Punto Vendita',
  //                         allowClear: true,
  //                         matcher: function (params, data) {

  //                             //console.log(params, data)

  //                             // If there are no search terms, return all of the data
  //                             if ($.trim(params.term) === '') {
  //                                 return data;
  //                             }

  //                             // Do not display the item if there is no 'text' property
  //                             if (typeof data.text === 'undefined') {
  //                                 return null;
  //                             }

  //                             // `params.term` should be the term that is used for searching
  //                             // `data.text` is the text that is displayed for the data object
  //                             if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1 || data.id == -1) {
  //                                 var modifiedData = $.extend({}, data, true);
  //                                 //modifiedData.text += ' (matched)';

  //                                 // You can return modified objects from here
  //                                 // This includes matching the `children` how you want in nested data sets
  //                                 return modifiedData;
  //                             }

  //                             // Return `null` if the term should not be displayed
  //                             return null;

  //                         },
  //                         ajax: {
  //                             url: '/get-pharmacies',
  //                             dataType: 'json',
  //                             data: function (params) {

  //                                 var query = {
  //                                     s: params.term,
  //                                 }

  //                                 return query;
  //                             },
  //                             processResults: function (data) {

  //                                 data.unshift({
  //                                     id: -1,
  //                                     text: '+++NUOVA FARMACIA+++',
  //                                     indirizzo: '',
  //                                     citta: '',
  //                                 })

  //                                 return {
  //                                     results: data
  //                                 };

  //                             },
  //                         },
  //                         templateResult: function (item) {

  //                             if (item.loading || !item.indirizzo) {
  //                                 return item.text;
  //                             }

  //                             let $template = $(`<div class="pharma-result">

  //                                 <div class="pharma-name"></div>

  //                                 <small class="pharma-data"></small>

  //                             </div>`)

  //                             $template.find('.pharma-name').text(item.text)

  //                             $template.find('.pharma-data').text(`${item.indirizzo} - ${item.citta}`)

  //                             return $template

  //                         },
  //                         templateSelection: function (item) {

  //                             return item.name || item.text
  //                         },
  //                         // escapeMarkup: function(markup) {
  //                         //     return markup;
  //                         //   }
  //                     },

  //                 },
  //             ],
  //             errors: {
  //                 name: 'Inserisci un nome valido',
  //                 surname: 'Inserisci un cognome valido',
  //                 email: 'Inserisci una email valida',
  //                 //company: 'Inserisci una ragione sociale valida',
  //                 pharmacy: 'Inserisci una farmacia valida',
  //                 role: 'Inserisci un ruolo valido',
  //                 city: 'Inserisci una città valida',
  //                 phone: 'Inserisci un numero di telefono valido',
  //                 is_password_accettable: 'Scegli una password più sicura',
  //                 password: '',
  //                 password_check: 'Le password non coincidono',
  //                 remember: 'Accetta la privacy policy',
  //                 remember2: 'Accetta i termini d\'uso',
  //                 recaptcha: 'Clicca su \"Non sono un robot\"',
  //             },
  // //            password_mode: false,
  //             password_mode: {
  //                 field: '#password',
  //                 acceptable_score: 35,
  //                 options: password_options_it
  //             },
  //             remote_errors: [
  //                 //{ param: 's', value: '403', message: "Email non abilitata, registrati con l'indirizzo su cui hai ricevuto l'invito" },
  //                 //{ param: 's', value: '409', message: 'Utente già registrato, vai al <a class="text-highlight" href="login.html" target="_blank">log-in</a>' },
  //                 {param: 's', value: '409', message: 'Utente già registrato'},
  //                 {param: 's', value: '422', message: 'Password errata'},
  //                 {param: 's', value: '400', message: 'Errore recaptcha, riprova'},
  //             ],
  //         })

  //         const is_register = registerFormValidator.initialize()

  //     }

  function init_register_event() {
    var $actionBox = init_actionbox();
    var registerFormValidator = new FormValidator({
      form: {
        id: "#register-form-event",
        errors: ".register-errors",
        btn_submit: "#btn-register",
        recaptcha: "#g-recaptcha-response"
      },
      fields: ["name", "surname", "email", "remember"],
      init_fields: false,
      errors: {
        name: 'Inserisci un nome valido',
        surname: 'Inserisci un cognome valido',
        email: 'Inserisci una email valida',
        remember: 'Accetta la privacy policy',
        recaptcha: 'Clicca su \"Non sono un robot\"'
      },
      password_mode: false,
      remote_errors: [
      //{ param: 's', value: '403', message: "Email non abilitata, registrati con l'indirizzo su cui hai ricevuto l'invito" },
      //{ param: 's', value: '409', message: 'Utente già registrato, vai al <a class="text-highlight" href="login.html" target="_blank">log-in</a>' },
      {
        param: 's',
        value: '409',
        message: 'Utente già registrato'
      }, {
        param: 's',
        value: '400',
        message: 'Errore recaptcha, riprova'
      }]
    });
    var is_register = registerFormValidator.initialize();
  }
  function init_login() {
    var loginFormValidator = new FormValidator({
      form: {
        id: "#login-form",
        errors: ".login-errors",
        btn_submit: "#btn-login",
        recaptcha: "#g-recaptcha-response"
      },
      fields: ["email"],
      errors: {
        email: 'Inserisci un\'email valida',
        recaptcha: 'Clicca su \"Non sono un robot\"'
      },
      password_mode: false,
      remote_errors: [{
        param: 's',
        value: '422',
        message: 'Email inesistente o password errata'
      }, {
        param: 's',
        value: '400',
        message: 'Errore recaptcha, riprova'
      }]
    });
    var is_login = loginFormValidator.initialize();
  }
  function init_forgot() {
    var forgotFormValidator = new FormValidator({
      form: {
        id: "#forgot-form",
        errors: ".forgot-errors",
        btn_submit: "#btn-forgot",
        recaptcha: "#g-recaptcha-response"
      },
      fields: ["email"],
      errors: {
        email: 'Inserisci un\'email valida',
        recaptcha: 'Clicca su \"Non sono un robot\"'
      },
      password_mode: false,
      remote_errors: [{
        param: 's',
        value: '422',
        message: 'Email inesistente o password errata'
      }, {
        param: 's',
        value: '400',
        message: 'Errore recaptcha, riprova'
      }]
    });
    var is_forgot = forgotFormValidator.initialize();
  }
  ;
  function init_change_password() {
    var change_passwordFormValidator = new FormValidator({
      form: {
        id: "#password-form",
        errors: ".password-errors",
        btn_submit: "#btn-password",
        recaptcha: "#g-recaptcha-response"
      },
      fields: ["password", "password_check"],
      errors: {
        is_password_accettable: 'Scegli una password più sicura',
        password: '',
        password_check: 'Le password non coincidono',
        recaptcha: 'Clicca su \"Non sono un robot\"'
      },
      password_mode: {
        field: '#password',
        acceptable_score: 35,
        options: password_options_it
      },
      remote_errors: [{
        param: 's',
        value: '400',
        message: 'Errore recaptcha, riprova'
      }]
    });
    var is_change_password = change_passwordFormValidator.initialize();
  }
  ;
  function init_access() {
    var accessFormValidator = new FormValidator({
      form: {
        id: "#access-form",
        errors: ".access-errors",
        btn_submit: "#btn-access"
        //recaptcha: "#g-recaptcha-response",
      },

      fields: ["name", "surname", "email"],
      errors: {
        name: 'Inserisci un nome di almeno 2 caratteri',
        surname: 'Inserisci un cognome valido',
        email: 'Inserisci una email valida'
      },
      password_mode: false,
      remote_errors: []
    });
    var is_access = accessFormValidator.initialize();
  }
  function init_live() {
    if (window.milc_conf && window.milc_conf.av_live) {
      window.AVLive = new AVLive();
    }
    console.debug('-----> AVLive setup ', window.AVLive);
  }
  function init_survey() {
    var poll_box_id = '#poll-box';
    var survey = $(poll_box_id);
    if (survey.length == 0) return;
    console.debug('-----> init survey ', survey);
    var ratings = $('.rating');
    var btn_send = $('#btn-send-survey');
    var poll = $('.poll-1');
    var poll_sending = $('.poll-sending');
    var poll_intro = $('.poll-intro');
    var poll_outro = $('.poll-outro');
    var poll_select;
    // var poll_select = $( '#poll-2-select' ).select2( {
    //     placeholder: "Scegli una risposta",
    //     allowClear: false,
    //     minimumResultsForSearch: -1,
    //     closeOnSelect: false
    // }).on("select2:closing", function(e) {

    //     e.preventDefault();

    // }).on("select2:closed", function(e) {

    //     poll_select.select2("open");

    // })

    //poll_select.select2("open");

    var pollNum = 1;
    window.startPoll = function () {
      poll_intro.hide();
      $('.poll-' + pollNum).removeClass('d-none');
    };
    $('#poll-box .prev-btn').click(function () {
      $('.poll-' + pollNum).addClass('d-none');
      pollNum--;
      $('.poll-' + pollNum).removeClass('d-none');
    });
    $('#poll-box .next-btn').click(function () {
      $('.poll-' + pollNum).addClass('d-none');
      pollNum++;
      if ($('.poll-' + pollNum).length) {
        $('.poll-' + pollNum).removeClass('d-none');
      }
      if (pollNum == 3) {
        console.log('open');
        poll_select = $('#poll-2-select').select2({
          placeholder: "Scegli una risposta",
          allowClear: false,
          minimumResultsForSearch: -1,
          closeOnSelect: false,
          dropdownPosition: 'below'
        }).on("select2:closing", function (e) {
          e.preventDefault();
        }).on("select2:closed", function (e) {
          poll_select.select2("open");
        }).on("select2:select", function (e) {
          poll_select.parents('.poll').find('button.next-btn').removeAttr('disabled');
        });
        poll_select.select2("open");
      }
    });
    ratings.each(function () {
      var box = $(this);
      $('.star', box).each(function (idx) {
        $(this).click(function () {
          $(box).removeClass(['value-1', 'value-2', 'value-3', 'value-4', 'value-5']).addClass('value-' + (idx + 1));
          $('.label-value', box).text('' + (idx + 1) + ' / 5');
          $(box).parents('.poll').find('button.next-btn').removeAttr('disabled');
        });
      });
    });
    var $last_poll_slide = $('#poll-box .poll-slide').last();
    $last_poll_slide.find('.next-btn').click(function () {
      var value = [];
      $('.rating .label-value').each(function () {
        value.push(parseInt($(this).text().replace(' / 5')));
      });
      var data = {
        value: JSON.stringify(value),
        select: poll_select.select2('data')[0].id
      };
      console.log('risultato survey', data);
      poll_select.select2('destroy');
      poll_sending.removeClass('d-none');
      $.ajax({
        type: "POST",
        async: true,
        url: window.survey_url,
        cache: false,
        data: data
      }).done(function () {
        setTimeout(function () {
          poll_sending.addClass('d-none');
          poll_outro.removeClass('d-none');
        }, 1500);
      }).fail(function (response) {
        console.error(response);
      });
    });
    //setTimeout( startPoll, 3000 );
  }

  $(function () {
    init_common();

    // init_register();

    init_register_event();
    init_login();

    //init_access();

    init_forgot();
    init_change_password();
    init_live();

    //        init_survey();
  });
});